import React from 'react'

const AwardsSection = ({ awardsSection }) => {
  const mappedAwards = awardsSection.awards.map((award) => (
    <div className="hover-zoom column is-6 award-item">
      <h3>{award.title}</h3>
      <div>{ award.year }</div>
      <div>{award.description}</div>
    </div>
  ));
  return (
    <div className="full-width awards-container">
      <div className="section">
        <div className="content container awards-section">      
          <div className="columns is-variable is-8 is-multiline">
            <div className="column is-3">
              <h2>{awardsSection.title}</h2>
              <hr className="hr-black" />
              <div>{awardsSection.subtitle}</div>
            </div>
            <div className="column is-9">
              <div className="columns is-variable is-8 is-multiline">
              {mappedAwards}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AwardsSection;
