import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BackgroundImage from '../components/BackgroundImage';
import TextImageColumns from '../components/TextImageColumns';
import AwardsSection from '../components/AwardsSection';
import PageHelmet from '../components/PageHelmet';
import AboutFeature from '../components/AboutFeature';
import { ImageOverlay } from '../utils';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const AboutUsTemplate = ({
  heroImageObject,
  textImageSection,
  awardsSection,
  smallTitle,
  imageTitle,
  seoObject,
  about,
  title,
  helmet,
}) => (
  <div className='page-container container content about-us-page'>
    {helmet || ''}
    <PageHelmet seoObject={seoObject} />
    <div className='container'>
      <div className='small-title'>{smallTitle}</div>
      <h1 className='page-title'>{title}</h1>
      <BackgroundImage
        imageInfo={heroImageObject.image}
        classes='page-header-image'>
        <div className='image-title'>
          <hr />
          {imageTitle}
        </div>
        <ImageOverlay overlay={heroImageObject.overlay} />
      </BackgroundImage>
    </div>
    <AboutFeature about={about} />
    <TextImageColumns textImageSection={textImageSection} />
    <AwardsSection awardsSection={awardsSection} />
  </div>
);

const AboutUsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <AboutUsTemplate
        title={frontmatter.title}
        smallTitle={frontmatter.smallTitle}
        imageTitle={frontmatter.imageTitle}
        heroImageObject={frontmatter.heroImageObject}
        textImageSection={frontmatter.textImageSection}
        about={frontmatter.about}
        awardsSection={frontmatter.awardsSection}
        seoObject={frontmatter.seoObject}
        location={location}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUsPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        title
        smallTitle
        imageTitle
        heroImageObject {
          image
          overlay
        }
        textImageSection {
          imagePlacement
          title
          text
          button {
            buttonText
            linkObject {
              link
              isExternal
            }
          }
          imageObject {
            image
            imageTitle
            imageSubtitle
          }
          backgroundTriangle
          backgroundTitle
        }
        seoObject {
          metaTitle
          metaDescription
        }
        about {
          title
          content
          feature_title
          feature_one {
            image
            title
            content
          }
          feature_two {
            image
            title
            content
          }
        }
        awardsSection {
          title
          subtitle
          awards {
            title
            year
            description
          }
        }
      }
    }
  }
`;
