import React from 'react'
import Image from './Image'
import { HTMLContent } from './Content'
import BackgroundTriangleAndTitle from './BackgroundTriangleAndTitle'

const AboutFeature = ({ about }) => (
  <div>
    <BackgroundTriangleAndTitle position="left" title="" />
    <div class="container content index-page-header">
      <h2>{about.title}</h2>
      <div class="horizontal-rule-black"></div>
    </div>
      
    <HTMLContent content={ about.content } />

    <div>

      <div class="container content index-page-header">
        <h2>{about.feature_title}</h2>
        <div class="horizontal-rule-black"></div>
      </div>

      <div className="columns">
        <div className="about-feature-section column is-6">
          <div className="about-feature-image-container">
            <Image imageInfo={{ image: about.feature_one.image }} classes="about-feature-image" />
          </div>
          <h3>{ about.feature_one.title }</h3>
          <div>{about.feature_one.content}</div>
        </div>

        <div className="about-feature-section column is-6">
          <div className="about-feature-image-container">
            <Image imageInfo={{ image: about.feature_two.image }} classes="about-feature-image" />
          </div>
          <h3>{about.feature_two.title}</h3>
          <div>{about.feature_two.content}</div>
        </div>
      </div>
    
    </div>
  </div>
);

export default AboutFeature
