import React from 'react'
import VisibilitySensor from "react-visibility-sensor";
import Image from './Image'
import BackgroundTriangleAndTitle from '../components/BackgroundTriangleAndTitle'
import LinkComponent from '../components/LinkComponent'
import { HTMLContent } from '../components/Content'
import { animateStyles } from '../utils'

const TextImageColumns = ({ textImageSection }) => {
  const image = (
    <div className="column is-7">
      <Image imageInfo={{ image: textImageSection.imageObject.image }} />
      <h4 className="title text-image-title">{ textImageSection.imageObject.imageTitle }</h4>
      <div className="text-image-subtitle">{ textImageSection.imageObject.imageSubtitle }</div>
    </div>
  );

  const text = (    
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <div className='column is-5 vertically-centered is-flex' style={isVisible ? animateStyles['fadeIn'].finish : animateStyles['fadeIn'].start}>      
          <h2>{textImageSection.title}</h2>
          <hr className="hr-black" />
          <HTMLContent content={textImageSection.text} />
          <LinkComponent linkObject={textImageSection.button.linkObject}>
            <div className="btn btn-main text-image-button">{textImageSection.button.buttonText}</div>
          </LinkComponent>
        </div>
      )}
    </VisibilitySensor>
  ) 
  return (
    <div className="container content slider-text-section">
      <BackgroundTriangleAndTitle position={ textImageSection.backgroundTriangle } title={ textImageSection.backgroundTitle } />
      <div className="columns is-variable is-6">
        { textImageSection.imagePlacement === 'left' ? image : text }
        { textImageSection.imagePlacement === 'left' ? text : image }
      </div>
    </div>
  );
};

export default TextImageColumns;
